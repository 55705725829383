'use strict';
(function($) {
    var phones = [{
        "mask": "###-###-#### Ext. ######"
      }, {
        "mask": "###-###-####"
      }];

    // No numbers in name fields
    $(document).on("keypress", "#input_36_3_3,#input_37_3_3,#input_36_3_6,#input_37_3_6,.alpha_only", function(e) {
        var keyCode = e.which ? e.which : e.keyCode
        if ((keyCode >= 48 && keyCode <= 57)) {
        $(".error").css("display", "inline");
        return false;
        } else {
        $(".error").css("display", "none");
        }
    });

    //No text in fields
    var number_only_inputs = "#input_46_1,#input_40_17,#input_40_7,#input_40_14,#input_36_21,#input_36_22,#input_36_23,#input_36_24,#input_37_21,#input_37_22,#input_37_23,#input_37_24,#input_37_66,#input_36_18,#input_36_26,#input_36_41,#input_39_1,.number_only";
    $(document).on("keypress", number_only_inputs, function(e) {
        var keyCode = e.which ? e.which : e.keyCode
        if (!(keyCode >= 48 && keyCode <= 57)) {
          $(".error").css("display", "inline");
          return false;
        } else {
          $(".error").css("display", "none");
        }
    }).on("change", number_only_inputs, function(e) {
        $(this).val($(this).val().toLowerCase().replace(/[^0-9]+/g, ""));
    });

    //Numbers only with decimals
    var dec_only_inputs = ".decimal_only input";
    $(document).on("keypress", dec_only_inputs, function(e) {
        var keyCode = e.which ? e.which : e.keyCode
        if (!(keyCode >= 48 && keyCode <= 57) && keyCode != 46 && keyCode != 188 && keyCode != 190) {
          $(".error").css("display", "inline");
          return false;
        } else {
          $(".error").css("display", "none");
        }
    }).on("change", dec_only_inputs, function(e) {
        $(this).val(parseFloat($(this).val().toLowerCase().replace(/[^0-9\.]+/g, "")));
    });

    // Auto disable schedule pickup
    if ($("#scheduledpickups").length) {
        setTimeout(
          function() {
            if (!$("#choice_39_2_1").is(":checked")) {
              $("#choice_39_2_1").trigger("click");
            }
            $("#choice_39_2_1").attr("onclick", "return false;");
          },
          $("#choice_39_2_1").length ? 1 : 100
        );
    }

    $(document).on('keypress', '#input_36_25', function(event) {
        if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
          event.preventDefault();
        }
        var input = $(this).val();
        if ((input.indexOf('.') != -1) && (input.substring(input.indexOf('.')).length > 2)) {
          event.preventDefault();
        }
    });

    $(document).on('gform_post_render', function(event, form_id) {
        $(".gfield_error input, .gfield_error select").each(function() {
          var value = $(this).val().trim();
          if (value.match(/p\.* *o\.* *box/i) || value.match(/p\.* *o\.* *-box/i)) {
            // Custom po box validation
          } else if ($(this).prop('type') != "checkbox" && value != "") {
            $(this).addClass("is-valid");
          } else if ($(this).is("select") && value != "") {
            $(this).addClass("is-valid");
          }
          if ($(this).attr("id") == "input_39_18" || $(this).attr("id") == "input_39_19") {
            $(this).removeClass("is-valid").addClass("is-invalid");
          }
        });

        // Phone mask
        $('.inputMaskPhone input, .inputMaskPhone').inputmask({
            mask: phones,
            greedy: false,
            definitions: {
                '#': {
                    validator: "[0-9]",
                    cardinality: 1
                }
            }
        });
    });

})(jQuery);