'use strict';
// for registration form, refer to wp-content/plugins/gcs-features/modules/gravity-forms/address-field/js/address-autocomplete.js
( function($) {
  $(document).ready(function () {
    var $theForm = $( '.edit-account-properties' );
  
    if ( $theForm && 'CA' === $theForm.data( 'country' ) ) {
      ac_cp_autoComplete();
    } else if ( $theForm && 'US' === $theForm.data( 'country' ) ) {
      $(".address_selector", '.edit-account-properties').each(function() {
				var addressSearch = new ac_hereups_autoComplete( this );
				// console.log("double");
	
				addressSearch.search();
			});
    }
  
    function ac_cp_autoComplete() {
      var cpFields = [   
        { element: 'hs_company_search', field: "Label", mode: pca.fieldMode.DEFAULT }, 
    
        { element: 'hs_company_address1', field: "Line1", mode: pca.fieldMode.POPULATE },
        { element: 'hs_company_address2', field: "Line2", mode: pca.fieldMode.POPULATE },
        { element: 'hs_company_city', field: "City", mode: pca.fieldMode.POPULATE },
        { element: 'hs_company_zip', field: "PostalCode", mode: pca.fieldMode.POPULATE },
    
      ],
      cpFields2 = [   
        { element: 'hs_shipping_search', field: "Label", mode: pca.fieldMode.DEFAULT }, 
    
        { element: 'hs_shipping_address1', field: "Line1", mode: pca.fieldMode.POPULATE },
        { element: 'hs_shipping_address2', field: "Line2", mode: pca.fieldMode.POPULATE },
        { element: 'hs_shipping_city', field: "City", mode: pca.fieldMode.POPULATE },
        { element: 'hs_shipping_zip', field: "PostalCode", mode: pca.fieldMode.POPULATE },
    
      ],
      options = {   
        key: ThemeJS.Variables.AutoAddress.address_canadapost_api,
        bar: {
          showCountry: false,
          showLogo: true,
          logoLink: false,
        }
      },
      cpControl = new pca.Address(cpFields, options),
      cpControl2 = new pca.Address(cpFields2, options);

      $( '#hs_company_address1' ).data( 'value', $( '#hs_company_address1' ).val() );
      $( '#hs_company_address2' ).data( 'value', $( '#hs_company_address2' ).val() );
      $( '#hs_company_city' ).data( 'value', $( '#hs_company_city' ).val() );
      $( '#hs_company_zip' ).data( 'value', $( '#hs_company_zip' ).val() );

      $( '#hs_shipping_address1' ).data( 'value', $( '#hs_shipping_address1' ).val() );
      $( '#hs_shipping_address2' ).data( 'value', $( '#hs_shipping_address2' ).val() );
      $( '#hs_shipping_city' ).data( 'value', $( '#hs_shipping_city' ).val() );
      $( '#hs_shipping_zip' ).data( 'value', $( '#hs_shipping_zip' ).val() );
	
      cpControl.listen("populate", function (address) {
        if ( address.ProvinceCode !== $( '#hs_company_state_province' ).val() ) {
          alert( ThemeJS.Variables.AutoAddress.address_move );
          $( '#hs_company_address1' ).val( $( '#hs_company_address1' ).data( 'value' ) );
          $( '#hs_company_address2' ).val( $( '#hs_company_address2' ).data( 'value' ) );
          $( '#hs_company_city' ).val( $( '#hs_company_city' ).data( 'value' ) );
          $( '#hs_company_zip' ).val( $( '#hs_company_zip' ).data( 'value' ) );
        } else {
          $( '#hs_company_address1' ).data( 'value', $( '#hs_company_address1' ).val() );
          $( '#hs_company_address2' ).data( 'value', $( '#hs_company_address2' ).val() );
          $( '#hs_company_city' ).data( 'value', $( '#hs_company_city' ).val() );
          $( '#hs_company_zip' ).data( 'value', $( '#hs_company_zip' ).val() );
        }
      });
      cpControl2.listen("populate", function (address) {
        if ( address.ProvinceCode !== $( '#hs_shipping_state_province' ).val() ) {
          alert( ThemeJS.Variables.AutoAddress.address_move );
          $( '#hs_shipping_address1' ).val( $( '#hs_shipping_address1' ).data( 'value' ) );
          $( '#hs_shipping_address2' ).val( $( '#hs_shipping_address2' ).data( 'value' ) );
          $( '#hs_shipping_city' ).val( $( '#hs_shipping_city' ).data( 'value' ) );
          $( '#hs_shipping_zip' ).val( $( '#hs_shipping_zip' ).data( 'value' ) );
        } else {
          $( '#hs_shipping_address1' ).data( 'value', $( '#hs_shipping_address1' ).val() );
          $( '#hs_shipping_address2' ).data( 'value', $( '#hs_shipping_address2' ).val() );
          $( '#hs_shipping_city' ).data( 'value', $( '#hs_shipping_city' ).val() );
          $( '#hs_shipping_zip' ).data( 'value', $( '#hs_shipping_zip' ).val() );
        }
      });
    }
    
    function ac_hereups_autoComplete( element ) {
      var AUTOCOMPLETION_URL = 'https://geocode.search.hereapi.com/v1/geocode';
      var APPLICATION_ID = '9MgSUHUwbP2IhpyuhJ0y',
      APPLICATION_CODE = '6Z22h3b8Fr_Vcft56K-rwZV7be8DnWzq7HV6Sh4XRrg';

      var that = this;
      this.typingTimer = false;
      this.results = [];
      this.element = element;

      this.mode = 'suggest';
      this.init = false;
      this.canSearch = true;
    
      this.search = function() {
        var search = $(".address_finder", that.element).val();
        // var search = get_address();
        var addresses = 0;
        var verified;
        var is_result = false;
    
        var  countryLimit = '&in=countryCode%3AUSA';
    
        $(".list-group", that.element).html('');
    
        // console.log( that.canSearch );
        if (search.length > 5 && that.canSearch) {
          var params = 'q=' +  encodeURIComponent( search + ', ' + $(".state_field input", that.element).val()  ) +
            //'&app_id=' + APPLICATION_ID +
            countryLimit +
            '&apiKey=' + APPLICATION_CODE;
    
          $.get(AUTOCOMPLETION_URL, params, function(response) {
            var existingSearch = false;
    
            that.results = response;
    
            $(".list-group", that.element).html('<small class="pb-2">' + ThemeJS.Variables.AutoAddress.address_suggestion + '</small>');
    
            // console.log( response );
            for (var i in response.items) {
              if ( response.items[i].houseNumberType == "PA" && response.items[i].address.postalCode.length >= 5 || response.items[i].resultType == "houseNumber" && response.items[i].address.postalCode.length >= 5 ) {
                if ( response.items[i].address.countryCode == "CAN" || response.items[i].address.countryCode == "USA" ) {
                  
                  // Detect an exact match
                  if ( response.items[i].scoring.fieldScore ) {
                    var quality = 0;
                    for (var x in response.items[i].scoring.fieldScore) {
                      quality++;
                    }
    
                    
                    if (quality >= 6) {
                      var address_1_numbers = $(".address_1_field input", that.element).val().match(/[0-9\- ]+/, "")[0].trim();
                      var address_1_street = $(".address_1_field input", that.element).val().replace(address_1_numbers, "").trim();
    
                      response.items[i].address.CustomNumber = address_1_numbers;
    
                    }
    
                    addresses++;
                  }
                  address_template = ThemeJS.Variables.AutoAddress.address_template;
                  $(".list-group", that.element).append(address_template.replace('{address_id}', i).replace("{address_content}", response.items[i].address.label));
                  is_result = true;
                }
              }
            }
    
            // $(".list-group a", that.element).append( " <small>" + 'Select' + "</small>" );
    
            if (!is_result) {
              $(".list-group", that.element).html('<i>' + ThemeJS.Variables.AutoAddress.address_not_found + '</i>');
            }
            //
            $(".list-group", that.element).show();
            that.init = true;
          });
        } else {
          $(".list-group", that.element).html('<i>' + ThemeJS.Variables.AutoAddress.address_not_found + '</i>');
        }
        
      }
    
      $(".address_finder", element).on('keyup', function () {
        clearTimeout(that.typingTimer);
        that.typingTimer = setTimeout(that.search, 350);
        // that.verified = false;
        // $(".address_valid", that.element).val( 'false' );
      }).on('keydown', function () {
        //console.log(that.typingTimer);
        clearTimeout(that.typingTimer);
        // that.verified = false;
        // $(".address_valid", that.element).val( 'false' );
      }).on('blur', function() {
        clearTimeout(that.typingTimer);
        that.typingTimer = setTimeout(that.search, 350);
      });
    
      
      function get_address() {
        var address = $(".address_1_field input", that.element).val() + " " +
          $(".city_field input", that.element).val() + " " +
          $(".state_field select", that.element).val() + " " +
          $(".postal_field input", that.element).val() + " " +
          $(".country_field select", that.element).val();
    
        //console.log("address", address);
        
        return address ? address : $("input:first", that.element).val()
      }
    
      $(element).on("click", ".list-group-item", function(e) {
        e.preventDefault();
        
        that.canSearch = false;
        $(".list-group", that.element).hide();
    
        // console.log( $(this)[0].classList );
        // console.log( $(this)[0].classList.contains("active") );
        var hasActive = $(this).hasClass("active");
        // console.log( hasActive );
    
        var id = $(this).data("id");
        var obj = { search: $("input:first", element).val() , id: id };
        $(".address_index", that.element).val( JSON.stringify(obj) );
    
        $(".list-group-item small", that.element).text('');
        $(".list-group-item", that.element).removeClass('active show');

        $(".list-group-item", that.element).removeClass('active show');
        $(this).addClass('active show');
        $(".list-group", that.element).html('');
  
        $(".address_finder", that.element).val(that.results.items[id].address.label);
				$(".address_finder", that.element).parent().append( '<img class="gform_ajax_spinner ajax_spinner_small" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="">' );
        $(".address_finder", that.element).prop( 'readonly', 'readonly' );
        $(".address_1_field input", that.element).prop( 'readonly', 'readonly' );
        $(".city_field input", that.element).prop( 'readonly', 'readonly' );
        $(".postal_field input", that.element).prop( 'readonly', 'readonly' );
        $("#save_account_properties").prop( 'disabled', 'disabled' );
        
        jQuery.ajax({
          type: "POST",
          url: ThemeJS.Variables.Ajax.url,
          data: {
            action: 'aa_ups_address_validation',
            nonce: ThemeJS.Variables.Ajax.nonce,
            line1 : that.results.items[id].address.houseNumber + " " + that.results.items[id].address.street,
            city: that.results.items[id].address.city,
            state: that.results.items[id].address.stateCode,
          },
          success: function(result){
            // console.log( result );
            if ( result.data ) {
              var theLabel = 
                result.data.line1 + ', ' + 
                result.data.city + ', ' + 
                result.data.state + ' ' + 
                result.data.zip +
                ', US';
              $(".address_finder", that.element).val( theLabel );

              if ( result.data.state !== $(".state_field input", that.element).val() ) {
                alert( ThemeJS.Variables.AutoAddress.address_move );
              } else {
                $(".address_1_field input", that.element).val(result.data.line1);
                $(".city_field input", that.element).val(result.data.city);
                $(".postal_field input", that.element).val(result.data.zip);
              }
            } else {
              alert( ThemeJS.Variables.AutoAddress.address_ups_fail );
            }
          },
          error: function(result){
            // fallback if error
            console.log( result );
          },
          complete: function() {
            that.canSearch = true;
            $(".address_finder", that.element).parent().find( '.ajax_spinner_small' ).remove();
            $(".address_finder", that.element).prop( 'readonly', false );
            $(".address_1_field input", that.element).prop( 'readonly', false );
            $(".city_field input", that.element).prop( 'readonly', false );
            $(".postal_field input", that.element).prop( 'readonly', false );
            $("#save_account_properties").prop( 'disabled', false );
          }
        } );
      });
    }
  } );
} )(jQuery);