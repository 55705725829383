/**
 * This will be appended at the end by JS pre-processor due to it's name
 */
let slickInit = false;

let resizeTimeSlot = 500;
let resizeLastTime = getUnixTimestampMilli();

(function ($) {
  $(document).ready(function () {

    conditionalSlick();

    $(window).on('resize', () => {
      if((resizeLastTime + resizeTimeSlot) > getUnixTimestampMilli()) return;

      resizeLastTime = getUnixTimestampMilli();

      window.PORTAL.resetPageHeight();
      window.PORTAL.resetMainAppMarginLeft();

      setTimeout( (function() { window.PORTAL.resetMainAppMarginLeft(); }), 250 );
    });

    window.PORTAL.resetPageHeight();
    window.PORTAL.resetMainAppMarginLeft();
  });
})(jQuery);

/**
 * Set main-app-content section max-height
 * because header and sidebar are fixed
 */
window.PORTAL = window.PORTAL || {};
window.PORTAL['resetPageHeight'] = function () {
  if($('body').hasClass('member-logged-in')) {
    let maxHeight = document.documentElement.clientHeight - $('.topbar').outerHeight() - 5;
    $('.main-app-content').css('max-height', `${maxHeight}px`);
  }
  conditionalSlick();
};

/**
 * Set main-app-content margin-left
 * because sidebar is fixed and using % margin will cause main-app to go under 
 * the sidebar at certain widths
 */
window.PORTAL['resetMainAppMarginLeft'] = function () {
  //$('.main-app-content').css('margin-left', `${$('.side-nav').outerWidth()}.px`);

  var test = $('.side-nav').outerWidth() + 'px';
  $('.main-app-content').css('margin-left', test);
}

function conditionalSlick() {
  /**
   * Do not apply slick on mobile
   */ 
  if($(window).width() > 575) {
    if( ! slickInit) {
      slickInit = true;
      initSlickSlider();
    }
  }
  else {
    slickInit = false;
    destroySlickSlider();
  }
}

function initSlickSlider() {
  var sliderConfig = {
    arrows: false,
    speed: 350,
    slidesToShow: 3,
    variableWidth: true,
    responsive: [{
      breakpoint: 576
    }]
  };
  var sliderConfigMarketingStandard = {
    arrows: false,
    draggable: false,
    speed: 350,
    infinite: false,
    slidesToShow: 1,
    variableWidth: true,
  };
  var sliderConfigMarketingVideo = {
    arrows: false,
    draggable: false,
    speed: 350,
    infinite: false,
    slidesToShow: 1,
    variableWidth: true,
  };
  var sliderConfigWrapped = {
    arrows: false,
    draggable: false,
    speed: 350,
    infinite: false,
    slidesToShow: 1,
    variableWidth: true,
    center: true,
    dots: true
  };

  $('.slider-wrapper .slider').each((i, el) => {
    let $this = $(el);
    var theConfig = sliderConfig;
    var minimum = 2;

    if ( $this.hasClass( 'images_slider' ) || $this.hasClass( 'files_slider' ) || $this.hasClass( 'pop_slider' ) ) {
      theConfig = sliderConfigMarketingStandard;
      minimum = 1;
    } else if ( $this.hasClass( 'videos_slider' ) ) {
      theConfig = sliderConfigMarketingVideo;
      minimum = 1;
    } else if ( $this.hasClass( 'gcs-wrapped-entries' ) ) {
      theConfig = sliderConfigWrapped;
      minimum = 1;
    }

    if($this.children().length > minimum) {
      /**
       * Saving the orignal html to use after unslick
       * Not using btoa()/atob() because they don't handle unicode
       * not using blob.text() because safari is IE of today
       */
      let blob = new Blob([$this.parent().html()], {type : 'text/html'});
      let reader = new FileReader();
      let parent = $this.parent();
      reader.addEventListener('loadend', function(ev) {
        parent.attr('data-child', reader.result);
      }.bind(parent));
      reader.readAsText(blob);

      enableControls($this);
      $this.slick(theConfig);

      if (false === $this.slick('slickGetOption', 'infinite') ) {
        // $('.slider-wrapper .slider-controls .control.prev').addClass( 'slick-disabled' );
        $this.parent('.slider-wrapper').find('.slider-controls .prev').addClass( 'slick-disabled' );
      }
    }
  });

  $('.slider-wrapper .slider-controls .control').click(function (ev) {
    let $slide = $(this).parents('.slider-wrapper').find('.slider').eq(0);
    if($(this).hasClass('next')) {
      $slide.slick('slickNext');
    }
    else if($(this).hasClass('prev')) {
      $slide.slick('slickPrev');
    }
  });

  $('.slider').on('afterChange', function(event, slick, currentSlide){
    $slide = $( this );
    slick_set_disabled( $slide );
  }); 

}

function slick_set_disabled( $slide ) {
  if (false === $slide.slick('slickGetOption', 'infinite') ) {
    let $slick_idx = $slide.slick('slickCurrentSlide');
    let $slick_max = $slide.slick('getSlick').slideCount;
    let $slick_shw = $slide.slick('slickGetOption', 'slidesToShow');
    let $slick_ctr = $slide.slick('slickGetOption', 'centerMode');

    // console.log({
    //   idx: $slick_idx,
    //   max: $slick_max
    // });

    // $('.slider-wrapper .slider-controls .control').removeClass( 'slick-disabled' );
    $slide.parent('.slider-wrapper').find('.control').removeClass( 'slick-disabled' );
    if ( 0 === $slick_idx ) {
      // $('.slider-wrapper .slider-controls .control.prev').addClass( 'slick-disabled' );
      $slide.parent('.slider-wrapper').find('.control.prev').addClass( 'slick-disabled' );
    } else if ( false === $slick_ctr && $slick_max - $slick_shw === $slick_idx ) {
      // $('.slider-wrapper .slider-controls .control.next').addClass( 'slick-disabled' );
      $slide.parent('.slider-wrapper').find('.control.next').addClass( 'slick-disabled' );
    } else if ( true === $slick_ctr && $slick_max - 1 === $slick_idx ) {
      // $('.slider-wrapper .slider-controls .control.next').addClass( 'slick-disabled' );
      $slide.parent('.slider-wrapper').find('.control.next').addClass( 'slick-disabled' );
    }
  }
}

function destroySlickSlider() {
  $('.slider-wrapper .slider').each((i, el) => {
    let $this = $(el);

    if ( 
      $this.hasClass( 'images_slider' ) || 
      $this.hasClass( 'files_slider' ) || 
      $this.hasClass( 'pop_slider' ) || 
      $this.hasClass( 'videos_slider' ) || 
      $this.hasClass( 'gcs-wrapped-entries' )
    ) {
      initSlickSlider();
      slickInit = true;
      return;
    } else if($this.hasClass('slick-initialized')) {
      /**
       * Retrieving the orignal html to use after unslick
       */
      let $thisParent = $this.parent();
      $this.slick('unslick');
      $thisParent.html($thisParent.data('child'));
    }
  });
}

function enableControls($elm) {
  $elm
    .parents('.slider-wrapper')
    .eq(0)
    .find('.slider-controls')
    .eq(0)
    .addClass('active');
}

function getUnixTimestampMilli() {
  return (new Date()).getTime();
}

/**
 * This was for webiste, never called anywhere
 * search covid and remove everything found if you are reading this after 
 * 1 Jan, 2021
 */
function covid19Popup() {
  let shown = Cookies.get('covid-19-popup');
  if( ! shown) {
    $('#toggle_covid-19-popup').trigger('click');
  }

  $('#covid-19-popup').on('hidden.bs.modal', function (ev) {
    let expireInDays = 3650;
    Cookies.set('covid-19-popup', 'asked', { expires: expireInDays, path: '/' });
  });
}

/* PPE boxes*/
jQuery('body').on('added_to_cart',function(event, fragments, cart_hash, $button) {
	if($($button[0]).data('product_id') == 4031 || $($button[0]).data('product_id') == 4810 || $($button[0]).data('product_id') == 158560 || $($button[0]).data('product_id') == 158559){
    $('#toggle_ppe-popup').trigger('click');
	}
});
$("#quantity_ppe").change(function(){
	$("#addppelink").attr("data-quantity",$("#quantity_ppe").val());
});;

$( document.body ).on( 'wc_cart_button_updated', function(e, $button) {
  console.log("button added", $button);
  $("a.added_to_cart", $button.parent("div")).addClass('btn btn-green btn-block');
  $button.hide();
  $("input", $button.parent("div")).hide();
  //added_to_cart wc-forward

} );