'use strict';
$=jQuery;

$(document).ready(function ($) {

  function gfReadOnly() {
    $('.gform-readonly-dynamic:visible').each(function(index){
      var $item = $(this)
      var $input = $item.find('input, select');
      if($input.val() != '' && !$input.hasClass('d-none')){
        var className = "gform-readonly";
        $item.addClass(className);
        $input.addClass('d-none');
        if ($input.is('select')) {
          $input.after('<div class="gf-no-change-value">'+$("option:selected", $input).text()+'</div>');
        } else {
          $input.after('<div class="gf-no-change-value gf-not-select">'+$input.val()+'</div>');
        }
      }
    });
  }

  gfReadOnly();


  jQuery(document).on('gform_post_render', function(event, form_id, current_page){
    // code to be trigger when next/previous page is loaded
    gfReadOnly();
  });

  $('.btn-print-labels').click(function(){
    var $frame = $('#print-labels-frame');
    var src = $(this).attr('printLink');
    $(".iframe-loader").show();
    $("#labelsModal .modal-footer").hide();
    $frame.attr('src', src);
  });
});