'use strict';
$=jQuery;

$(document).ready(function ($) {
    // $('.btn-print-labels').click(function(e){
    //     embedVideo(this);
    // })
    $('#labelsModal .printBtn').click(function(){
        $("#print-labels-frame").get(0).contentWindow.print();
        // window.frames["print-labels-frame"].focus();
        // window.frames["print-labels-frame"].print();
    })


    $('#print-labels-frame').load(function() {
        $("#labelsModal .modal-footer").show();
        $(".iframe-loader").hide();
    });
    

    $('.btn-print-labels').click(function(){
        //console.log('close')
        var $frame = $('#print-labels-frame');
        //$frame.attr('src', "");

        var src = $(this).attr('printLink');
        //var $frame = $('#print-labels-frame');
        $(".iframe-loader").show();
        $("#labelsModal .modal-footer").hide();

        $frame.attr('src', src);

        // window.frames["print-labels-frame"].focus();
        // window.frames["print-labels-frame"].print();
        // $frame.attr('src', src); 
        // var $modal = $(".modal-video");
        // var $iframe = $modal.find("iframe")
        // $iframe.attr("src", "");
    });

    $('#labelsModal').on('hidden.bs.modal', function () {
        var $modal = $("#labelsModal");
        console.log('closed');
        var $iframe = $modal.find("iframe");
        $iframe.attr("src", "");
    })


    var shouldShow = $('.print-btn-wrapper .btn-print-labels').attr('autoShow')
    if(shouldShow){
        $('.print-btn-wrapper .btn-print-labels').click();
    }


    var shouldShowBoxes = $('.boxes-data-wrapper').attr('auto-show-boxes') == true
    if(shouldShowBoxes){
        console.log('called');
        $('#boxesModal').modal('show');
    }

    // $('#labelsModal .close').click(function(){
    //     //console.log('close')
    //     var $modal = $("#labelsModal");
    //     console.log('closed');
    //     var $iframe = $modal.find("iframe")
    //     $iframe.attr("src", "");
    // })


});