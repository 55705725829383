(function ($) {

    if ( $("#client-count-popup").length > 0 ) {

        $('#toggle_client-count-popup').trigger('click');

        $(document).on('gform_confirmation_loaded', function(event, formId){
            // code to be trigger when confirmation page is loaded
            if (formId == 46) {
                $(".form_46_confirm_hide").hide();
            }
        });

    }

})(jQuery);