'use strict';

(function ($) {

  $(document).on('gform_post_render', function (event, form_id, current_page) {
    authnet_gForm();
  });

  function authnet_gForm() {
		var $container = $( '#authnet-container' );

		if ($container.is(":visible")) {
      var $button = $( '.AcceptUI' );
      var $errorContainer = $( '#authnet-card-errors' );
      $errorContainer.addClass( 'd-none' ).removeClass( 'd-block alert alert-danger' );

      var warehouse = $( $button.attr("data-warehouse") ).val();
      var warehouseRegion = authnet_getWareHouseRegion( warehouse );
      console.log( warehouse + ' ' + warehouseRegion );
      
      var fields = {
        nonce: $container.attr("data-nonce"),
        last4: $container.attr("data-last4"),
        expYear: $container.attr("data-expiry-year"),
        expMonth: $container.attr("data-expiry-month"),
        brand: $container.attr("data-brand"),
      }

      $button.attr( 'data-apiLoginID', authnet_data.login_ids[ warehouseRegion ] );
      $button.attr( 'data-clientKey', authnet_data.public_client_keys[ warehouseRegion ] );
      $button.attr( 'data-acceptUIFormHeaderTxt', authnet_data.strings.header );
      $button.attr( 'data-acceptUIFormBtnTxt', authnet_data.strings.submit );
      $button.html( authnet_data.strings.label_button );

      $( fields.nonce ).val('');
      $( fields.last4 ).val('');
      $( fields.expYear ).val('');
      $( fields.expMonth ).val('');
      $( fields.brand ).val('');

      $container.append( authnet_data.script );
    }
  }

  window.authnet_paymentMethodResponseHandler = function( response ) {
    var $container = $( '#authnet-container' );
    var fields = {
      nonce: $container.attr("data-nonce"),
      last4: $container.attr("data-last4"),
      expYear: $container.attr("data-expiry-year"),
      expMonth: $container.attr("data-expiry-month"),
      brand: $container.attr("data-brand"),
    }

    var $infoContainer = $( '#authnet-card-info' );
    var $errorContainer = $( '#authnet-card-errors' );
    var cardInfo = '';
    var nonce = '';
    var last4 = '';
    var exp_year = '';
    var exp_month = '';
    var brand = '';
    // console.log( response );
    if ( response.messages.resultCode && 'Ok' === response.messages.resultCode ) {
      nonce = response.opaqueData.dataValue;
      last4 = response.encryptedCardData.cardNumber.slice( -4 );
      exp_year = '20' + response.encryptedCardData.expDate.slice( -2 );
      exp_month = response.encryptedCardData.expDate.slice( 0, 2 );
      cardInfo = '<pre>' + authnet_data.strings.label_card + ': ••• ' + last4 + ' (' + authnet_data.strings.label_exp+ ':' + response.encryptedCardData.expDate + ')</pre>';
      checkBrand1 = response.encryptedCardData.bin.slice( 0, 1 );
      if ( '4' === checkBrand1 ) {
        brand = 'visa';
      } else if ( '5' === checkBrand1 ) {
        brand = 'mastercard';
      } else if ( '6' === checkBrand1 ) {
        brand = 'discover';
      }
      if ( '' === 'brand' ) {
        checkBrand2 = response.encryptedCardData.bin.slice( 0, 2 );
        if ( '34' === checkBrand2 || '37' === checkBrand2 ) {
          brand = 'american express';
        } else {
          brand = 'other';
        }
      }

      $infoContainer.html( cardInfo );
      $errorContainer.html( '' );
      $errorContainer.addClass( 'd-none' ).removeClass( 'd-block alert alert-danger' );
    } else {
      console.log( response );
      $infoContainer.html( '' );
      $errorContainer.html( '<pre class="mb-0">invalid</pre>' );
      $errorContainer.addClass( 'd-block alert alert-danger' ).removeClass( 'd-none' );
    }

    $( fields.nonce ).val(nonce);
    $( fields.last4 ).val(last4);
    $( fields.expYear ).val(exp_year);
    $( fields.expMonth ).val(exp_month);
    $( fields.brand ).val(brand);
    $("#input_48_8").val($("#payment-method-gcs").val());
  };

  window.authnet_defaultResponseHandler = function( response ) {
    var $container = $( '#authnet-container' );
    var fields = {
      nonce: $container.attr("data-nonce"),
      last4: $container.attr("data-last4"),
      expYear: $container.attr("data-expiry-year"),
      expMonth: $container.attr("data-expiry-month"),
      brand: $container.attr("data-brand"),
    }

    var $infoContainer = $( '#authnet-card-info' );
    var $errorContainer = $( '#authnet-card-errors' );
    var cardInfo = '';
    var nonce = '';
    var last4 = '';
    var exp_year = '';
    var exp_month = '';
    var brand = '';
    if ( response.messages.resultCode && 'Ok' === response.messages.resultCode ) {
      nonce = response.opaqueData.dataValue;
      last4 = response.encryptedCardData.cardNumber.slice( -4 );
      exp_year = '20' + response.encryptedCardData.expDate.slice( -2 );
      exp_month = response.encryptedCardData.expDate.slice( 0, 2 );
      cardInfo = '<pre>' + authnet_data.strings.label_card + ': ••• ' + last4 + ' (' + authnet_data.strings.label_exp + ':' + response.encryptedCardData.expDate + ')</pre>';
      checkBrand1 = response.encryptedCardData.bin.slice( 0, 1 );
      if ( '4' === checkBrand1 ) {
        brand = 'visa';
      } else if ( '5' === checkBrand1 ) {
        brand = 'mastercard';
      } else if ( '6' === checkBrand1 ) {
        brand = 'discover';
      }
      if ( '' === 'brand' ) {
        checkBrand2 = response.encryptedCardData.bin.slice( 0, 2 );
        if ( '34' === checkBrand2 || '37' === checkBrand2 ) {
          brand = 'american express';
        } else {
          brand = 'other';
        }
      }

      $infoContainer.html( cardInfo );
      $errorContainer.html( '' );
      $errorContainer.addClass( 'd-none' ).removeClass( 'd-block alert alert-danger' );
    } else {
      console.log( response );
      $infoContainer.html( '' );
      $errorContainer.html( '<pre class="mb-0">invalid</pre>' );
      $errorContainer.addClass( 'd-block alert alert-danger' ).removeClass( 'd-none' );
    }

    $( fields.nonce ).val(nonce);
    $( fields.last4 ).val(last4);
    $( fields.expYear ).val(exp_year);
    $( fields.expMonth ).val(exp_month);
    $( fields.brand ).val(brand);
  };

  function authnet_getWareHouseRegion(province) {
		var NW = ["AK", "AZ", "CA", "HI", "ID", "MT", "NV", "OR", "UT", "WA", "WY"];
		var IL = ["AL", "AR", "CO", "CT", "DE", "FL", "GA", "IA", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MS", "NC", "ND", "NE", "NH", "NJ", "NM", "NY", "OH", "OK", "PA", "RI", "SC", "SD", "TN", "TX", "VA", "VT", "WI", "WV", "DC"];
		var AB = ["AB", "NT", "SK"];
		var BC = ["BC", "YK"];
		var ON = ["MB", "ON"];
		var QC = ["NB", "NF", "NS", "PE", "QC", "NL"];
		// 20220923 - introduction of GCI
		var GCICAN = ["GCICAN"];
		var GCIUSA = ["GCIUSA"];
		var warehouses = [NW, IL, AB, BC, ON, QC, GCICAN, GCIUSA];
		var ware = "";
		var warehouses_ref = ['NW', 'IL', 'AB', 'BC', 'ON', 'QC', 'GCICAN', 'GCIUSA'];
		for (var i = 0; i < warehouses.length; i++) {
			if (warehouses[i].indexOf(province) !== -1) {
				ware = i;
        break;
			}
		};
		return warehouses_ref[ ware ];
	}

})(jQuery);
