(function ($) {

    if ( $(".ppe-warning-popup").length > 0 ) {

        var allowSubmission = false;

        $("#gform_submit_button_38").on("click", function(e) {
            var number_of_ppe = $("#input_38_6").val();
            var price = $("#modal-ppe-box-price").attr('data-price');
            if ( parseInt(number_of_ppe) > 0 && number_of_ppe != "" && ! allowSubmission ) {
                e.preventDefault();
                e.stopPropagation();

                $("#modal-ppe-box-number").text(number_of_ppe);
                $("#modal-ppe-box-price").text((parseInt(number_of_ppe) * parseFloat(price)));
                $('#toggle_ppe-warning-popup').trigger('click');
                window["gf_submitting_38"] = false;
            }
        });
        
        // Places the order
        $(document).on("click", ".ppe-continue", function(e) {
            allowSubmission = true;
            $("#ppe-warning-popup .close").trigger('click');
            $("#gform_submit_button_38").trigger('click');
        });

        $(document).on("click", ".ppe-change", function(e) {
            $("#ppe-warning-popup .close").trigger('click');
        });

    }

})(jQuery);